import { ddCriteria } from "@/data/criteria";
import { useDocumentContext } from "../DocumentContext"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { ChevronDownIcon } from "lucide-react";


export default function CriteriaDropdown() {
  const { semanticSearchClass, setSemanticSearchClass, setActiveMatch } = useDocumentContext()

  return (
    <Select
      value={semanticSearchClass!}
      defaultValue={semanticSearchClass!}
      onValueChange={(value) => {
        if (value === semanticSearchClass) return
        setActiveMatch(null)
        setSemanticSearchClass(value)
      }}
    >
      <SelectTrigger className="w-1/2 font-500 bg-slate-100 border-none" hideDefaultIcon>
        <SelectValue />
        <ChevronDownIcon size={16} />
      </SelectTrigger>
      <SelectContent>
        {ddCriteria.map((criteria) => (
          <SelectItem
            key={criteria.title.toLowerCase().replaceAll(" ", "_")}
            value={criteria.title.toLowerCase().replaceAll(" ", "_")}
          >
            {criteria.title}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
