import { Link } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import { Folder, FileIcon, LoaderCircle, CircleSlash } from "lucide-react"
import { findStatus } from "@/utils/status"
import { SimpleTooltip } from "@/elements/simple-tooltip"
import { useNavigate } from "react-router";
import DocumentDropdown from "@/components/Documents/document/DocumentDropdown"

interface ResourceSchema {
  project_id: string
  document_id?: string | null
  resource_type: "document" | "directory"
  created_at: string
  updated_at?: string | null
  directory_id?: string | null
  display_name: string
  status?: any
  search_hits?: string[]
  assignedPerson?: {
    name: string
    avatar: string
  }
  dateAdded: string
}

interface DirectorySchema extends ResourceSchema {
  directory_id: string
  parent_id?: string | null
  resource_type: "directory"
  completed_documents: number
  total_documents: number
}

interface DocumentSchema extends ResourceSchema {
  document_id: string
  parent_id?: string | null
  status: "not_started" | "in_progress" | "in_review" | "done"
  index_status: "pending" | "success" | "failed"
  resource_type: "document"
  file_type: string
  title: string | null
  language: string | null
  semantic_matches?: {
    text: string
    idx: number
  }[]
}

type Resource = DirectorySchema | DocumentSchema

const FILES: Resource[] = [
  {
    project_id: "1",
    directory_id: "dir1",
    resource_type: "directory",
    display_name: "Project Documents",
    created_at: "2024-01-10T09:00:00Z",
    updated_at: "2024-01-15T14:30:00Z",
    completed_documents: 3,
    total_documents: 5,
    assignedPerson: {
      name: "Alice Johnson",
      avatar: "/placeholder.svg?height=32&width=32",
    },
    dateAdded: "2024-01-10T09:00:00Z",
  },
  {
    project_id: "1",
    document_id: "doc1",
    resource_type: "document",
    display_name: "Q4 Financial Report",
    created_at: "2023-12-15T10:30:00Z",
    updated_at: "2023-12-20T11:45:00Z",
    status: "done",
    index_status: "success",
    file_type: "pdf",
    title: "Q4 Financial Report 2023",
    language: "en",
    assignedPerson: {
      name: "Bob Smith",
      avatar: "/placeholder.svg?height=32&width=32",
    },
    dateAdded: "2023-12-15T10:30:00Z",
  },
  {
    project_id: "1",
    document_id: "doc2",
    resource_type: "document",
    display_name: "Product Roadmap 2024",
    created_at: "2024-01-05T14:45:00Z",
    updated_at: null,
    status: "in_progress",
    index_status: "pending",
    file_type: "xlsx",
    title: "Product Roadmap 2024",
    language: "en",
    assignedPerson: {
      name: "Carol Davis",
      avatar: "/placeholder.svg?height=32&width=32",
    },
    dateAdded: "2024-01-05T14:45:00Z",
  },
]

function getStatusColor(status: DocumentSchema["status"] | undefined) {
  switch (status) {
    case "done":
      return "bg-green-500"
    case "in_review":
      return "bg-yellow-500"
    case "in_progress":
      return "bg-blue-500"
    case "not_started":
      return "bg-gray-500"
    default:
      return "bg-gray-300"
  }
}

function getFileIcon(resource: ResourceSchema) {
  if (resource.resource_type === "directory") {
    return <Folder className="h-5 w-5 text-yellow-500" />
  }
  if ((resource as DocumentSchema).index_status === "pending") {
    return (
      <SimpleTooltip tooltip="Document is being analyzed ...">
        <LoaderCircle className="h-5 w-5 text-blue-500 animate-spin" />
      </SimpleTooltip>
    )
  }
  // FIXME: these states don't correspond to backend states
  if ((resource as DocumentSchema).index_status === "failed") {
    return (
      <SimpleTooltip tooltip="Document processing failed!">
        <CircleSlash className="h-5 w-5 text-red-500" />
      </SimpleTooltip>
    )
  }
  return <FileIcon className="h-5 w-5 text-blue-500" />
}

function getTableRows(resources) {
  const navigate = useNavigate()
  return resources.map((resource) => (
    <TableRow
      className="hover:bg-gray-100 cursor-pointer"
      onClick={() => {
        navigate(`/document/${
          resource.resource_type === "directory"
            ? resource.directory_id
            : resource.document_id
        }`)
      }}
      key={
        resource.resource_type === "directory"
          ? resource.directory_id
          : resource.document_id
      }
    >
        <TableCell>
          {getFileIcon(resource)}
        </TableCell>
        <TableCell className="font-medium">
          <span>{resource.display_name}</span>
        </TableCell>
        <TableCell>
          {resource.resource_type === "directory"
            ? "Directory"
            : (resource as DocumentSchema).file_type.toUpperCase()}
        </TableCell>
        <TableCell>
          {resource.resource_type === "directory" ? (
            <span>
              {(resource as DirectorySchema).completed_documents} /{" "}
              {(resource as DirectorySchema).total_documents} completed
            </span>
          ) : (
            <Badge
              variant="secondary"
              className={`${getStatusColor((resource as DocumentSchema).status)} text-white`}
            >
              {findStatus((resource as DocumentSchema).status)?.name}
            </Badge>
          )}
        </TableCell>
        <TableCell>{new Date(resource.created_at).toLocaleString()}</TableCell>
        <TableCell>
          {resource.assignedPerson && (
            <div className="flex items-center space-x-2">
              <Avatar className="h-8 w-8">
                <AvatarImage
                  src={resource.assignedPerson.avatar}
                  alt={resource.assignedPerson.name}
                />
                <AvatarFallback>
                  {resource.assignedPerson.name
                    .split(" ")
                    .map((n) => n[0])
                    .join("")}
                </AvatarFallback>
              </Avatar>
              <span>{resource.assignedPerson.name}</span>
            </div>
          )}
        </TableCell>
        <TableCell>
          <DocumentDropdown document={resource} />
        </TableCell>
    </TableRow>
  ))
}

export default function FilesTable({ resources }) {
  // folders in list example: 
  // const rows = resources ? getTableRows([FILES[0], ...resources]) : []

  const rows = resources ? getTableRows([...resources]) : []

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-5"></TableHead>
          <TableHead className="w-[300px]">Name</TableHead>
          <TableHead>Type</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Date Added</TableHead>
          <TableHead>Assigned To</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>{rows}</TableBody>
    </Table>
  )
}
