import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Calendar } from "@/components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { CalendarIcon, FilterIcon, X } from "lucide-react"
import { cn } from "@/lib/utils"
import { format } from "date-fns"
import ProjectsSearch from "@/components/Project/project/main/ProjectsSearch";
import {
  useGetProjectQuery,
  useGetProjectDocumentsWithFilterQuery,
  useGetProjectPartiesQuery,
  useGetDirectoryQuery
} from "@/api/resources";
import { useProjectContext } from "@/components/Project/ProjectContext";
import { STATUS_DROPDOWN_VALUES } from "@/types/status";
import Status, { DropdownValue } from "@/elements/status";
import {useQueryParamsState} from "@/utils/hooks";
import { FilterBadge } from "@/components/Project/FilterBadge";
import { CheckboxSelect } from "./CheckboxSelect";
import { ddCriteria } from "@/data/criteria";

export const FilterContext = createContext<any>(null)
export type Tabs = "keywords" | "semantic"

export function FilterProvider({ children, folderId }: { children: React.ReactNode, folderId?: string }) {

  // originally from keywordstab
  const [query, setQuery] = useState<string>("")
  const [keywords, setKeywords] = useQueryParamsState<string[]|null>('keywords', null)
  // originally from semantictab
  const [provisions, setProvisions] = useQueryParamsState<string[]|null>('provisions', null)

  // originally from projectssearch
  // TODO: remove this
  const [activeTab, setActiveTab] = useState<Tabs>(provisions ? "semantic" : "keywords")

  // new filtering criteria
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [titleFilter, setTitleFilter] = useQueryParamsState("title", "")
  const [statusFilter, setStatusFilter] = useQueryParamsState<DropdownValue|null>("status", null)
  const [partyFilters, setPartyFilters] = useQueryParamsState<string[]>("parties", [])
  const [dateRange, setDateRange] = useQueryParamsState<{ from: Date | undefined; to: Date | undefined }>("daterange", {
    from: undefined,
    to: undefined,
  })
  const hasActiveFilters = keywords?.length > 0 || provisions?.length > 0 || titleFilter || statusFilter || partyFilters?.length > 0 || (dateRange.from && dateRange.to)

  useEffect(() => {
    if (activeTab == "keywords" && provisions != null) {
      setProvisions(null)
    }
    if (activeTab == "semantic" && keywords != null) {
      setKeywords(null)
    }
  }, [activeTab])

  ///
  const project = useProjectContext()

  // here we can be either in a project or folder
  const rootResponse = useGetProjectQuery(
    {project_id: project?.project_id ?? ""},
    {skip: project?.project_id == undefined || !!hasActiveFilters || folderId != null}
  )
  const folderResponse = useGetDirectoryQuery(folderId ?? "", 
    {skip: project?.project_id == undefined || !!hasActiveFilters || folderId == null}
  )
  const regularResponse = folderId == null ? rootResponse : folderResponse

  // otherwise we're filtering documents (either in project or in folder)
  const filteredResponse = useGetProjectDocumentsWithFilterQuery({
    project_id: project?.project_id || "",
    folder_id: folderId ?? null,
    title_or_filename: titleFilter ?? null,
    status: statusFilter?.id ?? null,
    // TODO: get provision objects from backend
    provisions: provisions && provisions.length > 0 ? provisions.map(x => x.toLowerCase().replaceAll(" ", "_")) : null,
    parties: partyFilters?.length ? partyFilters : null,
    keywords: keywords?.length ? keywords : null,
  }, {skip: project?.project_id == undefined || !hasActiveFilters})
  ///

  const data = hasActiveFilters ? filteredResponse.data : regularResponse.data?.contents
  const isLoading = hasActiveFilters ? filteredResponse.isLoading : regularResponse.isLoading

  return <FilterContext.Provider
    value={{
      activeTab, setActiveTab,
      query, setQuery,
      keywords, setKeywords,
      provisions, setProvisions,
      isSidebarOpen, setIsSidebarOpen,
      titleFilter, setTitleFilter,
      statusFilter, setStatusFilter,
      partyFilters, setPartyFilters,
      dateRange, setDateRange,
      hasActiveFilters,
      ///
      data, isLoading
    }}
  >
    {children}
  </FilterContext.Provider>
}

export const useFilterContext = () => {
  const ctx = useContext(FilterContext)
  if (ctx === undefined) {
    throw new Error("useFilterContext called outside of FilterProvider");
  }
  return ctx
}

export function SidebarButton () {
  {/* Sidebar toggle button for small screens */}
  const {isSidebarOpen, setIsSidebarOpen} = useFilterContext()
  return (
    <Button
      variant="outline"
      // size="icon"
      className="top-4 left-4 z-50 h-[36px]"
      // xxl:hidden
      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
    >
      <FilterIcon className="h-4 w-4 mr-2" />
      <span>Search or filter</span>
    </Button>
  )
}

export function FiltersView() {

  const {
    hasActiveFilters, isSidebarOpen, setIsSidebarOpen, provisions, setProvisions, titleFilter, setTitleFilter, 
    statusFilter, setStatusFilter, partyFilters, setPartyFilters, dateRange, setDateRange,
    setKeywords
  } = useFilterContext()

  const removeFilter = (type: string, value?: string) => {
    switch (type) {
      case 'title':
        setTitleFilter("")
        break
      case 'status':
        setStatusFilter(null)
        break
      case 'party':
        if (value) setPartyFilters(prev => prev.filter(p => p !== value))
        break
      case 'date':
        setDateRange({ from: undefined, to: undefined })
        break
    }
  }

  const clearAllFilters = () => {
    setKeywords(null)
    setProvisions(null)
    setTitleFilter("")
    setStatusFilter(null)
    setPartyFilters([])
    setDateRange({ from: undefined, to: undefined })
  }

  return (
  <>
    {/*<h1 className="text-2xl font-bold mb-4">Document List</h1>*/}
    {/* Active filters */}
    <div className="flex flex-wrap gap-2">
      <SidebarButton isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

      {provisions && provisions.map((provision) => (
        <FilterBadge text={`Provision: ${provision}`} onClick={() => setProvisions(provisions.filter(x => x !== provision))} />
      ))}

      {titleFilter && (
        <FilterBadge text={`Title: ${titleFilter}`} onClick={() => removeFilter('title')} />
      )}
      {statusFilter && (
        <FilterBadge text={`Status: ${statusFilter.name}`} onClick={() => removeFilter('status')} />
      )}
      {partyFilters?.map(party => (
        <FilterBadge key={party} text={`Party: ${party}`} onClick={() => removeFilter('party', party)} />
      ))}
      {dateRange.from && dateRange.to && (
        <FilterBadge text={`Date: ${format(dateRange.from, "LLL dd, y")} - ${format(dateRange.to, "LLL dd, y")}`} onClick={() => removeFilter('date')} />
      )}
      {hasActiveFilters && (
        <Button
          variant="ghost"
          size="sm"
          className="ml-2"
          onClick={clearAllFilters}
        >
          <X className="h-4 w-4 mr-2" />
          Clear All
        </Button>
      )}
    </div>
  </>
  )
}

export function Sidebar() {

  const {hasActiveFilters, isSidebarOpen, setIsSidebarOpen, titleFilter, setTitleFilter, statusFilter, setStatusFilter, partyFilters, setPartyFilters, dateRange, setDateRange} = useFilterContext()
  const { provisions, setProvisions } = useFilterContext()

  const project = useProjectContext()
  const {data: parties} = useGetProjectPartiesQuery(project?.project_id, {skip: project?.project_id == undefined})
  const sortedParties = useMemo(() => {
    return parties?.toSorted((a,b) => a.name.localeCompare(b.name)) ?? []
  }, [parties])

  const handlePartyChange = (party: string) => {
    setPartyFilters(prev =>
      prev.includes(party) ? prev.filter(p => p !== party) : [...prev, party]
    )
  }

  return (
    <>
      {/* Sidebar */}
      <aside
        className={cn(
          "h-[90%] bg-background transition-all bg-white z-10 shadow-lg overflow-y-scroll",
          isSidebarOpen ? "p-4 border-r border-t basis-64" : "basis-0 overflow-hidden xxl:translate-x-0"
        )}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Filter Documents</h2>
          <Button
            variant="ghost"
            size="icon"
            // className="xxl:hidden"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close sidebar</span>
          </Button>
        </div>
        {/* Keywords / provisions filter */}
        <ProjectsSearch />

        {/* Provisions filter */}
        <CheckboxSelect 
          items={ddCriteria.map(x => x.title)}
          checkedItems={provisions ?? []}
          toggleChecked={(title) => {
            const newProvisions = provisions?.includes(title) ? 
              provisions.filter(x => x !== title) 
              : [...(provisions ?? []), title]
            setProvisions(newProvisions)
          }}
          label="Provisions"
        />

        <form className="space-y-4 mt-2">
          {/* Title filter */}
          <div>
            <Label htmlFor="title">Title</Label>
            <Input
              id="title"
              placeholder="Filter by title..."
              value={titleFilter}
              onChange={(e) => setTitleFilter(e.target.value)}
            />
          </div>

          <Status
            values={[{id: "", name: "(none)", color: ""}, ...STATUS_DROPDOWN_VALUES]}
            selectedValue={statusFilter}
            setSelectedValue={(x) => {
              if (x.id) setStatusFilter(x)
              else setStatusFilter(null)
            }}
            placeholder={"Document status"}
          />

          {/* Parties filter */}
          <CheckboxSelect 
            items={sortedParties.map(x => x.name)}
            checkedItems={partyFilters}
            toggleChecked={(partyName) => handlePartyChange(partyName)}
            label="Parties"
          />

          {/* Date range filter */}
          <div className="space-y-2">
            <Label>Date Range</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !dateRange.from && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {dateRange.from ? (
                    dateRange.to ? (
                      <>
                        {format(dateRange.from, "LLL dd, y")} -{" "}
                        {format(dateRange.to, "LLL dd, y")}
                      </>
                    ) : (
                      format(dateRange.from, "LLL dd, y")
                    )
                  ) : (
                    <span>Pick a date</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  initialFocus
                  mode="range"
                  defaultMonth={dateRange.from}
                  selected={dateRange}
                  onSelect={setDateRange}
                  numberOfMonths={2}
                />
              </PopoverContent>
            </Popover>
          </div>

          {/*<Button className="w-full">Apply Filters</Button>*/}
        </form>
      </aside>
    </>
  )
}