import DocumentDropdown from "@/components/Documents/document/DocumentDropdown"
import { ProjectStatus } from "@/elements"
import { Link } from "react-router-dom"
import { DocumentSchema } from "@/api/resources"
import clsx from "clsx"
import PulsingIndicator from "./PulsingIndicator"

interface DocumentProps {
  document: DocumentSchema
  toggleSelected: (id: string) => void
  selected: string[]
}

export default function Document({
  document,
  toggleSelected,
  selected,
}: DocumentProps) {
  const mentions = document.search_hits?.length ?? 0
  const indexStatus = document.index_status
  // TODO: properly rename the status-es, it's incorrect now

  return (
    <div
      onClick={() => toggleSelected(document.document_id)}
      className={clsx(
        "overflow-hidden gap-2 justify-between rounded-10 py-2 px-4 relative flex flex-col",
        selected.includes(document.document_id)
          ? "border-blue-200"
          : "border-border-gray",
      )}
      style={{
        // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        aspectRatio: "1 / 1",
        // width: "225px",
        flex: '0 1 225px',
    }}
    >
      {/*<div style={squareStyle} />*/}
      {/*<Icon name="Document" className="absolute left-0 right-0 top-0 bottom-0 object-cover p-2 w-full h-full opacity-[0.05]" />*/}

      {/* Item header */}
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          {indexStatus !== "chunking_success" ? (
            <PulsingIndicator
              variant={indexStatus === "pending" || indexStatus === "indexing_success" ? "pending" : "error"}
              tooltips={{
                pending: "Processing your document.",
                error: "Document processing failed",
              }}
            />
          ) : null}

          <p
            className={clsx(
              "rounded-5 px-1.5 text-12 leading-6 text-text-neutral bg-[#F0F4F8]",
              mentions ? "block" : "hidden",
            )}
          >
            {mentions} keywords
          </p>
        </div>

        {/* TODO: n_mentions is confusing because right now it's n_keywords */}
        <DocumentDropdown document={document} />
      </div>

      {/* Item content */}
      <Link
        className="flex flex-col gap-2 items-center flex-grow overflow-x-hidden pb-2"
        to={`/document/${document.document_id}`}
      >
        <p className="flex-1 text-center line-clamp-2 break-all text-md font-medium">{document.display_name} </p>
        <p className="flex-1 text-center line-clamp-2 break-words text-md text-gray-400">{document.title ?? "-"}</p>
        <p className="text-14 leading-4 text-gray py-2 uppercase flex gap-1 items-center">
          <span className="opacity-40">{document?.file_type ?? "??"}</span>
          {document.language ? (
            <span className="bg-blue-200 py-0.5 px-1 rounded-md text-[12px] text-white uppercase opacity-80">
              {document.language}
            </span>
          ) : null}
        </p>

        <ProjectStatus status={document?.status ?? ""} />
      </Link>
    </div>
  )
}
