import { useMemo, useState } from "react"
import { useDocumentContext } from "../DocumentContext"
import { DdCriterion } from "@/data/criteria"

function Chip ({ text }: {text: string}) {
    return <div className="rounded px-1 bg-blue-600 text-white text-xs">{text}</div>
}

export function SemanticSearchResults ({ selectedClasses }: { selectedClasses: DdCriterion[] }) {
    const { semanticSearchData, activeMatch, setActiveMatch } = useDocumentContext()

    const shownClassIds = useMemo(() => {
        return selectedClasses?.map(x => x.id) ?? []
    }, [selectedClasses])

    const data = semanticSearchData?.text_chunks?.map((x, i) => ({
        index: i,
        matchIndex: i,
        text: x.text,
        classes: [] as string[]
    }))

    data && semanticSearchData.provisions && Object.entries(semanticSearchData.provisions)?.filter(x => shownClassIds.includes(x[0]))?.forEach((x) => {
        const [key, value] = x
        if (typeof value !== "string") {
            value.predictions.forEach((value, index) => {
                if (value != 0) data[index].classes.push(key)
                    // FIXME: use user labels
            })
        }
    })
    //.predictions[i]

    return <div>
        {data?.filter((x) => x.classes.length > 0).map((x) => (
            <div 
                className={`p-3 my-2 rounded border hover:bg-zinc-100 ${activeMatch === x.matchIndex ? 'bg-zinc-100' : 'bg-white'}`}
                onClick={() => {
                    setActiveMatch(x.matchIndex)
                }}
            >
                <div className="flex gap-1">
                    {x.classes.map(cls => <Chip text={cls.replaceAll("_", " ")}/>)}
                </div>
                <span>{x.text}</span>
            </div>
        ))}
    </div>
}