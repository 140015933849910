import { useState } from "react"
import { Trash2Icon, PencilIcon, UsersIcon, FileSpreadsheetIcon, BriefcaseBusinessIcon, ChartAreaIcon } from "lucide-react"

import { Icon } from "@/assets"
import { Button } from "@/components/ui/button"
import { DeleteProjectDialog } from "@/components/allProjects/DeleteProjectDialog"
import { CreateOrRenameProjectDialog } from "@/components/allProjects/CreateOrRenameProjectDialog"
import { ProjectSchema } from "@/api/resources/types"
import { Link } from "react-router-dom"

interface TopProps {
  project: ProjectSchema
  toggleDropdown: () => void
  toggleShare: () => void
  onExportProject: () => void
}

export default function ProjectHeader({
  project,
  toggleDropdown,
  toggleShare,
  onExportProject,
}: TopProps) {
  const [isRenameModal, setRenameModal] = useState<boolean>(false)
  const [isDeleteModal, setDeleteModal] = useState<boolean>(false)

  return (
    <div className="bg-slate-50 w-full flex justify-center">
      <div className="flex flex-wrap gap-y-2 justify-between py-5 wrapper">
        <div className="flex items-center gap-2 text-24 text-black font-500">
          <BriefcaseBusinessIcon className="w-7 h-7 stroke-[1.5px] text-slate-600 mr-1" />
          {project.display_name}
          <p className="bg-blue-200 text-white px-2 py-0.5 text-xs rounded-full capitalize font-semibold">
            {project.role}
          </p>
        </div>

        <div className="flex flex-wrap items-center text-14 leading-6">
          <Button
            variant="ghost"
            className="text-blue-200 gap-1.5"
            onClick={toggleDropdown}
          >
            <Icon name="Export" />
            Upload Document
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-2.5 text-slate-600"
            onClick={onExportProject}
          >
            <FileSpreadsheetIcon className="w-[16px] h-[16px]" />
            Generate Report
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Link to={{
              pathname: `/projects/${project.project_id}/metrics`
            }}>
          <Button
            variant="ghost"
            className="gap-2.5 text-slate-600"
          >
                <ChartAreaIcon className="w-[16px] h-[16px]" />
                Metrics
          </Button>
          </Link>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-2.5 text-slate-600"
            onClick={toggleShare}
          >
            <UsersIcon className="w-[16px] h-[16px]" />
            Permissions
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-2.5 text-slate-600"
            onClick={() => {
              setRenameModal(true)
            }}
          >
            <PencilIcon className="w-[16px] h-[16px]" />
            {/* Rename */}
          </Button>

          {project.role == "owner" && (
            <>
              <div className="h-4 border-l border-[#B6CADE]" role="none"></div>
              <Button
                variant="ghost"
                className="gap-2.5"
                style={{ color: "#E57373" }}
                disabled={isDeleteModal}
                onClick={() => {
                  setDeleteModal(true)
                }}
              >
                <Trash2Icon className="w-[16px] h-[16px]" />
                {/* Delete */}
              </Button>
            </>
          )}
        </div>
      </div>
      <CreateOrRenameProjectDialog
        projectId={project.project_id}
        initialName={project.display_name}
        action="rename"
        open={isRenameModal}
        onOpenChange={setRenameModal}
      />
      <DeleteProjectDialog
        projectId={project.project_id}
        open={isDeleteModal}
        onOpenChange={setDeleteModal}
      />
    </div>
  )
}
