import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
//   DialogTrigger,
} from "@/components/ui/dialog"
import { ddCriteria, DdCriterion } from "@/data/criteria";  // TODO: take this from backend


interface ModalParams {
    isOpen: boolean
    setOpen: (x: boolean) => void
    selectedClasses: DdCriterion[]
    setSelectedClasses: (x: DdCriterion[]) => void
}

export default function ClassSelectionModal({ isOpen, setOpen, selectedClasses, setSelectedClasses } : ModalParams) {
  const [checkedItems, setCheckedItems] = useState<DdCriterion[]>(selectedClasses)

  const handleCheckboxChange = (item: DdCriterion) => {
    setCheckedItems((prev) =>
      prev.includes(item) ? prev.filter((x) => x.id !== item.id) : [...prev, item]
    )
  }

  const handleOk = () => {
    setSelectedClasses(checkedItems)
    setOpen(false)
  }

  const handleCancel = () => {
    setCheckedItems([])
    setOpen(false)
  }

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      {/* <DialogTrigger asChild>
        <Button variant="outline">Open Modal</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Select Classes</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-4">
          {ddCriteria.map((item) => (
            <div key={item.id} className="flex items-center space-x-2">
              <Checkbox
                id={item.id}
                checked={checkedItems.includes(item)}
                onCheckedChange={() => handleCheckboxChange(item)}
              />
              <label
                htmlFor={item.id}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {item.title}
              </label>
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

