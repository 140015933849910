import Documents from "@/components/Documents"
import { criteriaData } from "@/data/criteria"
import SemanticMatchedDocuments from "@/components/Documents/SemanticMatchedDocuments"
import { useFilterContext } from "@/components/Project/project/Filtering"
import ControlledDropdown from "@/elements/ControlledDropdown"
import { Label } from "@/components/ui/label";

export function SemanticDropdown() {
  const { provision, setProvision } = useFilterContext()
  const onChange = (val: string) => {
    if (val == "(none)") {
      setProvision(null)
    } else {
      setProvision(val)
    }
  }
  return (
    <div>
      <Label>Provision</Label>
      <ControlledDropdown
        value={provision ?? "(none)"}
        onChange={onChange}
        placeholder="Choose a value..."
        items={["(none)", ...criteriaData]}
      />
    </div>
  )
}

export default function SemanticTab() {
  const { provisions, data, isLoading } = useFilterContext()

  return (
    <div className="pt-10">
      {isLoading ? (
        <div>Loading...</div>
      ) : provisions ? (
        <SemanticMatchedDocuments documents={data} provisions={provisions} />
      ) : (
        <Documents matches={data.length} documents={data} />
      )}
    </div>
  )
}
