import { useMemo, useState } from "react"

import { Button, Modal } from "@/elements"
import { CreateOrEditSearchModal } from "@/components/mySavedSearches/CreateOrEditSearchModal"
import { getIntersection } from "@/utils/keywords"
import { useDocumentContext } from "../../DocumentContext"

interface searchMatchProps {
  children: React.ReactNode
  handleSave: (
    e: {
      preventDefault: () => void
    },
    name: string,
  ) => void
  keywords: string[]
}

export default function SearchMatch({
  children,
  handleSave,
  // matches,
  keywords,
  activeMatch,
  setActiveMatch,
}: searchMatchProps) {
  const [openSaveSearch, setOpenSaveSearch] = useState<boolean>(false)

  const { keywordMatches: matches } = useDocumentContext()

  // TODO: this is identical to readOnlyEditor/index.tsx
  const keywordsPrepro = useMemo(() => {
    return keywords
      .map((keyword) => {
        return keyword.replaceAll(/\s+/g, " ").trim().toLowerCase()
      })
      .filter((x) => x.length > 0)
  }, [keywords])

  function findKeywordAndContext(text: string) {
    const textPrepro = text.toLowerCase()
    const textTokens = textPrepro.split(" ")
    // console.log(textPrepro)
    const { singleWordsIntersection, multiWordsIntersection } = getIntersection(
      text,
      keywordsPrepro,
    )
    const keywordsToFind = [
      ...multiWordsIntersection,
      ...singleWordsIntersection,
    ]
    // TODO: support multiple highlights !!!
    // TODO: this will have false positives -> map matches exactly !!!
    return highlightSpecificString(text, keywordsToFind)
  }

  // TODO: this is almost identical to readOnlyEditor/index.tsx -> refactor
  // TODO: also, if there are more keywords, it will only highlight the 1st one
  function highlightSpecificString(text: string, keywordsToFind: string[]) {
    for (let i = 0; i < keywordsToFind.length; i++) {
      const kw = keywordsToFind.at(i)
      // Search string is preprocessed, we need to also preprocess text
      const textPrepro = text.toLowerCase() // TODO: here i can't use unidecode bc it moves!
      const startPos = textPrepro.search(kw)
      if (startPos >= 0) {
        const endPos = startPos + kw.length
        // TODO: preserve styles?!
        const textBefore = text.substring(0, startPos)
        const textMatch = text.substring(startPos, endPos)
        const textAfter = text.substring(endPos)
        return (
          <>
            ...
            {textBefore.substring(
              Math.max(0, textBefore.length - 5),
              textBefore.length,
            )}
            <span style={{ backgroundColor: "#ACC8F8" }}>{textMatch}</span>
            {textAfter}
          </>
        )
      }
      return <span>{keywordsToFind}</span>
    }
  }

  // TODO: useMemo
  // TODO: use objects for matches so that i can scroll to the correct line
  const matchesWithHighlight = matches.map((match, idx) => {
    return (
      <div
        key={idx}
        className={`rounded-9 px-6 py-4 text-gray font-400 text-15 text-ellipsis whitespace-nowrap overflow-hidden ${handleActiveMatchBg(
          activeMatch,
          match.index,
        )}`}
        onClick={() => {
          // set active match color
          setActiveMatch(match.index)
          // scroll to the active match
          document.getElementsByClassName(`match-${idx}`)[0].scrollIntoView()
          // const y_pos = element.getBoundingClientRect().top
        }}
      >
        {/*<span className="bg-background-lightBlue rounded text-blue-200 font-500 p-[3px]">*/}
        {/*  The Discloser intends*/}
        {/*</span>*/}
        <span className="text-gray-400">{idx + 1}.</span> {findKeywordAndContext(match.text)}
      </div>
    )
  })

  return (
    <div>
      <CreateOrEditSearchModal
        isOpen={openSaveSearch}
        setOpen={setOpenSaveSearch}
        initKeywords={keywords}
      />
      {children}
      <div className="pt-6 flex flex-col gap-4">
        <div className="flex justify-between">
          <p className="font-400 text-16 leading-6 text-gray-400">
            <span className="text-black">{matches.length}</span> matches found
          </p>
          <Button
            children={"Save Search"}
            onClick={() => {
              setOpenSaveSearch(true)
            }}
            className="sm-sec-btn"
          />
        </div>
        {matchesWithHighlight}
      </div>
    </div>
  )
}

const handleActiveMatchBg = (
  activeMatch: number | null,
  idx: number,
): string => {
  if (activeMatch !== null) {
    if (activeMatch === idx) return "bg-background-darkBlue/60"
    return "bg-blue-100/60"
  }

  return "bg-blue-100"
}
