import { Icon } from "@/assets"
import { Link } from "react-router-dom"
import ProgressBar from "./progressBar"
import FolderDropdown from "@/components/folders/FolderDropdown"
import { DirectorySchema } from "@/api/resources"
import { FolderIcon } from "lucide-react"

interface FolderProps {
  link: string
  folder: DirectorySchema
}

export default function Folder({ folder, link }: FolderProps) {
  const progress =
    folder.total_documents === 0
      ? 0
      : (folder.completed_documents / folder.total_documents) * 100

  return (
    <div className="flex flex-col gap-2 justify-between rounded-14 p-6 pr-4 w-full border border-border-gray">
      <div className="flex justify-between items-center">
        <FolderIcon className="w-7 h-7 text-slate-400 stroke-[1.5px]" />
        <FolderDropdown folder={folder} />
      </div>
      <div className="flex flex-col flex-1">
        <Link
          className="text-16 font-500 text-black flex-1 break-words"
          to={link}
        >
          {folder.display_name}
        </Link>

        {folder.total_documents === 0 ? (
          <p>No files</p>
        ) : folder.completed_documents === folder.total_documents ? (
          <p className="text-text-green">Done</p>
        ) : (
          <p className="font-500 text-14 leading-6 text-gray-400">
            <span className="text-black">
              {folder.completed_documents ?? 0}
            </span>{" "}
            of <span>{folder.total_documents ?? 0}</span> files done
          </p>
        )}

        <ProgressBar percentage={progress} />
      </div>
    </div>
  )
}
