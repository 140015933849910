import { Icon } from "@/assets"
import { Input } from "@/components/ui/input";

interface searchProps {
  query?: string
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  handlePaste?: React.ClipboardEventHandler<HTMLInputElement>
  placeholder?: string
  classname?: string
  autoFocus?: boolean
  smallVersion?: boolean
}

export const handlePasteFunction = (
  keywords: string[],
  setKeywords: (x: string[]) => void,
) => {
  const handlePaste = (e) => {
    //https://stackoverflow.com/questions/2176861/javascript-get-clipboard-data-on-paste-event-cross-browser
    // TODO: this won't work in older browsers !!!
    e.preventDefault()
    e.stopPropagation()

    const clipboardData = e.clipboardData || (window as any).clipboardData
    let pastedData = clipboardData.getData("Text")
    let pos = 0
    pastedData = pastedData.replaceAll("\r\n", "\n")
    pastedData = pastedData.replaceAll("\r", "\n")
    const newKeywords = []
    while (pos < pastedData.length) {
      let nextPos = (pastedData as string).indexOf("\n", pos)
      if (nextPos === -1) {
        nextPos = pastedData.length
      }
      if (pos < nextPos) {
        const keyword = pastedData.substring(pos, nextPos).trim()
        if (keyword.length > 0) {
          // if it wasn't just spaces ...
          newKeywords.push(keyword)
        }
      }
      pos = nextPos + 1
    }
    // now add each one line to keywords
    setKeywords([...keywords, ...newKeywords])
  }
  return handlePaste
}

export default function Search({
  query,
  handleChange,
  handleKeyPress,
  handlePaste,
  placeholder,
  classname,
  autoFocus = false,
  smallVersion = false,
}: searchProps) {
  return (
    <div
      className={`flex gap-2 items-center rounded ${classname} ${!smallVersion && "px-4"}`}
    >
      <Icon name="Search24" />
      <Input
        type="text"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        onPaste={handlePaste}
        placeholder={placeholder}
        className={smallVersion ? "font-500 w-full" : "pl-3 font-500 w-full"}
        autoFocus={autoFocus}
      />
    </div>
  )
}
