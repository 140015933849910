import { useMemo, useState } from "react"

import Keywords from "@/components/keywords"
import Search from "@/components/left/main/searchBar/search"
import FilesystemView from "../../FilesystemView"
import { useFilterContext } from "@/components/Project/project/Filtering";
import Library from "@/components/left/main/searchBar/library";

export function KeywordsSearch() {
  const {query, setQuery, keywords, setKeywords} = useFilterContext()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(e.target.value)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()

      if (keywords == null)
        setKeywords([query])
      else
        setKeywords([...keywords, query])
      setQuery("")
    }
  }

  return (
    <>
    <Search
      query={query}
      handleChange={handleChange}
      handleKeyPress={handleKeyPress}
      placeholder="Type keywords ⏎, or paste from excel"
      classname="placeholder-gray rounded-8"
      autoFocus
      smallVersion
    />
    <Library onSelect={(x) => {
      setKeywords(x?.keywords)
    }} />
    </>
  )
}

export default function KeywordsTab() {
  const {keywords, setKeywords, data, hasActiveFilters} = useFilterContext()

  const deleteKeyword = (idx: number) => {
    const existingKeywords = [...keywords]
    existingKeywords.splice(idx, 1)
    setKeywords(existingKeywords)
  }

  return (
    <div>
      <Keywords
        keywords={keywords}
        parentClass={`flex-wrap pb-4 ${
          keywords?.length > 0 ? "block" : "hidden"
        }`}
        childClass={
          "rounded-6 bg-background-darkGrey text-text-darkGrey font-500 text-16 leading-4 flex gap-2 items-center w-fit p-2"
        }
        deleteKeyword={deleteKeyword}
      />

      <FilesystemView hasActiveFilters={hasActiveFilters} data={data} />
    </div>
  )
}
