import { Icon } from "@/assets"
import CriteriaMatch from "./CriteriaMatch"
import CriteriaDropdown from "./CriteriaDropdown"
import { useDocumentContext } from "../DocumentContext"

export default function SimilarityCriteria() {
  const { openDefaultTab } = useDocumentContext()

  return (
    <div className="mt-4">
      <div className="flex items-center mb-3 gap-5">
        <button
          onClick={() => openDefaultTab()}
          className="px-3 py-2.5 flex center
            gap-2 bg-bgSecondary rounded-5
            text-12"
        >
          <Icon name="Close" />
          Clear search
        </button>
        <CriteriaDropdown />
      </div>
      <CriteriaMatch />
    </div>
  )
}
