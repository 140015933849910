import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Bounds, getPageBoundsFromCanvas } from "@/pdf/common";
import { PDFPageRenderer } from "@/pdf/PDFPageRenderer";
import { PDFPageProxy } from "pdfjs-dist/types/display/api";
import { Selection } from "./Selection"
import useIsOnScreen from "@/utils/hooks";
import { useDocumentContext } from "@/components/left/DocumentContext";
import { toast } from "sonner";

const PageAnnotationsContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);

    &:last-child {
        margin-bottom: 0;
    }
`
);

interface PageProps {
  //pageInfo: PDFPageInfo;
  pdfPage: Promise<PDFPageProxy>;
  onError: (_err: Error) => void;
  annotations: any;
}

interface ElementAnnotation {
  isUserValidated: any
  highlightColor: string|null
  label?: {text: string, color: string}
}

function getShownLabels(provisions, index) {
  // TODO: use label objects instead of string replace
  return provisions.map((x) => x[1].user_labels[index] > 0 ? x[0] : null).filter(x => x !== null).map(x => x.replaceAll("_", " ")).reduce((acc, val) => acc ? acc + "," + val : val, null)
}

export const Page = ({ pdfPage, onError, annotations }: PageProps) => {
  const canvasRef = useRef<HTMLCanvasElement|null>(null);
  const isVisible = useIsOnScreen(canvasRef)

  const [scale, setScale] = useState<number>(1);
  const [renderer, setRenderer] = useState<PDFPageRenderer|null>(null)
  const [page, setPage] = useState<PDFPageProxy>(null)
  const {
    activeMatch, 
    setActiveMatch, 
    allMatches, 
    keywordMatches, 
    setMatchUserLabel, 
    tab, 
    selectedClasses, 
    semanticSearchData
  } = useDocumentContext()

  const selectedClassIds = selectedClasses.map(x => x.id)
  const selectedClassProvisions = Object.entries(semanticSearchData?.provisions ?? []).filter((x) => selectedClassIds.includes(x[0]))

  const elementAnnotations: ElementAnnotation[]|null = useMemo(() => {
    if (tab === "overview") {
      // we're showing multiple uneditable highlights, possibly for entities or keyword search results
      // #5eead4: teal-300
      if (semanticSearchData && semanticSearchData.text_chunks) {
        let results: ElementAnnotation[] = semanticSearchData.text_chunks.map((value, index) => ({ isUserValidated: null, highlightColor: null, label: {text: getShownLabels(selectedClassProvisions, index), color: "#2563eb"} }))
        if (keywordMatches && keywordMatches.length > 0) {
          keywordMatches.forEach((map) => {
            const label = results[map.index].label
            // TODO: how to best display multiple labels?
            results[map.index].label = {text: "search", color: "#2563eb"}
            results[map.index].highlightColor = "#dbeafe"
          })
        }
        return results
      } else {
        return null
      }
    } else if (tab === "edit") {
      // we're showing highlights for one class only, with the option to edit by selecting/unselecting
      // allMatches: array of all displayable elements along with detection logits and user labels
      return allMatches && allMatches.length > 0 ? allMatches.map((x) => ({isUserValidated: x.isUserValidated, highlightColor: x.isUserValidated ? "rgb(134, 239, 172, 0.6)": null})) : null
    } else {
      console.warn("Invalid tab value")
      return null
    }
  }, [tab, allMatches, semanticSearchData, selectedClassProvisions])

  useEffect(() => {
    // await the pdf page (what does this do exactly?)
    pdfPage.then((pdf) => {
      setPage(pdf);
    })
  }, [])

  const containerRef = useRef<HTMLDivElement|null>(null);

  const [domPageWidth, domPageHeight] = useMemo(() => {
    if (!page) {return [0,0]}
    const viewport = page.getViewport({ scale });
    const domPageWidth = viewport.width;
    const domPageHeight = viewport.height;
    return [domPageWidth, domPageHeight];
  }, [page, scale])

  function getScale(page: PDFPageProxy, bounds: Bounds): number {
    if (bounds == null) {
      throw new Error('Unknown Page Bounds');
    }
    //An array of the visible portion of the PDF page in user space units [x1, y1, x2, y2].
    const pdfPageWidth = page.view[2] - page.view[0];
    const domPageWidth = bounds.right - bounds.left;
    return domPageWidth / pdfPageWidth;
  }

  const scaledAnnotations = useMemo(() => {
    return annotations.map((annotation) => ({
      ...annotation,
      xyxy: [
        annotation.xyxy[0] * domPageWidth,
        annotation.xyxy[1] * domPageHeight,
        annotation.xyxy[2] * domPageWidth,
        annotation.xyxy[3] * domPageHeight,
      ]
    }))
  }, [domPageWidth, domPageHeight, annotations])

  useEffect(() => {
    try {

      if (canvasRef.current == null) {
        onError(new Error('No canvas element'));
        return;
      }

      if (page == null) {
        return;
      }

      const bounds = getPageBoundsFromCanvas(canvasRef.current);
      const scale = getScale(page, bounds);
      const renderer = new PDFPageRenderer(page, canvasRef.current, onError);
      renderer.render(scale);
      setRenderer(renderer);
      setScale(scale);

      // has to be inside useEffect, so that window has the correct reference
      const handleResize = () => {
        if (canvasRef.current == null || !renderer) {
          return;
        }
        const bounds = getPageBoundsFromCanvas(canvasRef.current);
        const scale = getScale(page, bounds);
        renderer.rescaleAndRender(scale);
        setScale(scale);
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    } catch (e) {
      onError(e);
    }
  }, [page, onError]); // We deliberately only run this once.

  return (
    <PageAnnotationsContainer
      ref={containerRef}
      onClick={() => setActiveMatch(null)}
      // onMouseDown={(event) => {
      //   if (containerRef.current === null) {
      //     throw new Error('No Container');
      //   }
      //   if (!selection) {
      //     const left = event.pageX - containerRef.current.offsetLeft;
      //     const top = event.pageY - containerRef.current.offsetTop;
      //     setSelection({
      //       left,
      //       top,
      //       right: left,
      //       bottom: top,
      //     });
      //   }
      // }}
      // onMouseMove={
      //   selection
      //     ? (event) => {
      //       if (containerRef.current === null) {
      //         throw new Error('No Container');
      //       }
      //       setSelection({
      //         ...selection,
      //         right: event.pageX - containerRef.current.offsetLeft,
      //         bottom: event.pageY - containerRef.current.offsetTop,
      //       });
      //     }
      //     : undefined
      // }
      // onMouseUp={
      //   selection
      //     ? () => {
      //       if (annotationStore.activeLabel) {
      //         const newAnnotation = getNewAnnotation(
      //           // TODO(Mark): Change
      //           pageInfo,
      //           selection,
      //           annotationStore.activeLabel,
      //           annotationStore.freeFormAnnotations
      //         );
      //         if (newAnnotation) {
      //           annotationStore.setPdfAnnotations(
      //             annotationStore.pdfAnnotations.withNewAnnotation(
      //               newAnnotation
      //             )
      //           );
      //         }
      //       }
      //       setSelection(undefined);
      //     }
      //     : undefined
      // }
    >
      <canvas style={{ display: "block" }} className="border" ref={canvasRef} />
      {
        // We only render the tokens if the page is visible, as rendering them all makes the
        // page slow and/or crash.
        scale &&
        isVisible &&
        scaledAnnotations.map((annotation, i) => (
          <Selection
            annotation={annotation}
            isActive={activeMatch == annotation.index}
            isHighlighted={elementAnnotations ? elementAnnotations[annotation.index].highlightColor as boolean : false}
            highlightColor={elementAnnotations ? elementAnnotations[annotation.index].highlightColor: undefined}
            label={tab === "overview" && elementAnnotations ? elementAnnotations[annotation.index].label ?? null : null}
            key={i}
            onClick={() => setActiveMatch(annotation.index)}
            onToggleChecked={(e) => {
              e.stopPropagation();
              if (elementAnnotations && tab === "edit")
                setMatchUserLabel(annotation.index, !elementAnnotations[annotation.index].isUserValidated)
            }}
            onCopyText={(e) => {
              e.stopPropagation();
              const text = annotation.text;
              navigator.clipboard.writeText(text).then(() => {
                toast.info("Text copied to clipboard")
              })
            }}
          />
        ))
      }
      {/*{selection && annotationStore.activeLabel*/}
      {/*  ? (() => {*/}
      {/*    if (selection && annotationStore.activeLabel) {*/}
      {/*      const annotation = pageInfo.getAnnotationForBounds(*/}
      {/*        normalizeBounds(selection),*/}
      {/*        annotationStore.activeLabel*/}
      {/*      );*/}
      {/*      const tokens =*/}
      {/*        annotation &&*/}
      {/*        annotation.tokens &&*/}
      {/*        !annotationStore.freeFormAnnotations*/}
      {/*          ? annotation.tokens*/}
      {/*          : null;*/}

      {/*      return (*/}
      {/*        <>*/}
      {/*          <SelectionBoundary*/}
      {/*            color={annotationStore.activeLabel.color}*/}
      {/*            bounds={selection}*/}
      {/*            selected={false}*/}
      {/*          />*/}
      {/*          <SelectionTokens pageInfo={pageInfo} tokens={tokens} />*/}
      {/*        </>*/}
      {/*      );*/}
      {/*    }*/}
      {/*  })()*/}
      {/*  : null}*/}
    </PageAnnotationsContainer>
  );
};